import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { isMenuShownAtom } from './MainMenu/MainMenuState';
import { isNavigatorDisabledAtom, isOpenAtom } from './Navigator/NavigatorState';

interface IPageContentProps {
  children: React.ReactNode;
  isNavigatorDisabled?: boolean;
  isRideBook?: boolean;
}

function PageContent(props: IPageContentProps) {
  const menuToggle = useRecoilValue<boolean>(isMenuShownAtom);
  const isNavigatorOpen = useRecoilValue<boolean>(isOpenAtom);
  const isNavigatorDisabled = useRecoilValue<boolean>(isNavigatorDisabledAtom);

  const pageContentWrapperStyle = {
    marginLeft: !menuToggle ? '-163px' : 0,
    marginRight:
      isNavigatorOpen && !props.isNavigatorDisabled && !isNavigatorDisabled ? '278px' : '0px',
  };
  return props.isRideBook ? (
    <div id="page-content-wrapper" style={pageContentWrapperStyle}>
      {props.children}
    </div>
  ) : (
    <div id="page-content-wrapper" style={pageContentWrapperStyle}>
      <div id="page-content-wrapper-div" style={{ height: '100%', overflowY: 'auto' }}>
        <div id="subpage-root" />
        {props.children}
      </div>
    </div>
  );
}

export default PageContent;
