import { atom, selector } from 'recoil';
import { BluecoinData, BluecoinsData, FiltersBluecoin } from './types';
import Config from '../../../../config';
import { boundsAtom, isClusterEnabledAtom, zoomAtom } from '../OnlineMapState';
import { MIN_POINTS_FOR_DISABLED_CLUSTER } from '../GoogleMapLayer/constants';
import setIsBluecoinsMapOpenToSocketServerEffect from '../../../store/recoil/effects/setIsBluecoinsMapOpenToSocketServerEffect';
import { SuperclusterBluecoinWorker } from '../CreateWorkers';

export const bluecoinsAtom = atom<BluecoinsData>({
  key: 'bluecoins',
  default: {},
});

export const selectedBluecoinsAtom = atom<number[]>({
  key: 'selectedBluecoins',
  default: [],
});

export const isBluecoinPanelOpenAtom = atom<number | false>({
  key: 'isBluecoinPanelOpen',
  default: false,
});

export const isBluecoinListPanelOpenAtom = atom<number[] | false>({
  key: 'isBluecoinListPanel',
  default: false,
});

export const bluecoinsArraySelector = selector<BluecoinData[]>({
  key: 'bluecoinsArray',
  get: ({ get }) => {
    const bluecoinsData = get(bluecoinsAtom);

    return (
      Object.keys(bluecoinsData)
        // .filter((bluecoinId: string) => selectedBluecoins.includes(Number(bluecoinId)))
        .map((bluecoinId: string) => {
          return bluecoinsData[Number(bluecoinId)];
        })
    );
  },
});

export const filteredBluecoinStringAtom = atom<string>({
  key: 'filteredBluecoinString',
  default: '',
});

export const filteredBluecoinsAtom = atom<BluecoinData[]>({
  key: 'filteredBluecoins',
  default: [],
});

export const activeFilterBluecoinsAtom = atom<FiltersBluecoin | null>({
  key: 'activeFilterBluecoins',
  default: null,
});

export const isBluecoinDataLoadingAtom = atom<boolean>({
  key: 'isBluecoinsLoaded',
  default: false,
});

export const isBluecoinsOpenAtom = atom<boolean>({
  key: 'isBluecoinsOpen',
  default: false,
  effects: [setIsBluecoinsMapOpenToSocketServerEffect()],
});

export const bluecoinIdAtom = atom<number | null>({
  key: 'bluecoinId',
  default: null,
});

export const bluecoinsDataSelector = selector<BluecoinData[]>({
  key: 'bluecoinsData',
  get: ({ get }) => {
    const selectedBluecoins = get(selectedBluecoinsAtom);
    const bluecoinsDataWorker = get(bluecoinsArraySelector).filter((bluecoin: BluecoinData) =>
      selectedBluecoins.includes(bluecoin.bluecoinId)
    );
    // const filteredBluecoinsString = get(filteredBluecoinStringAtom);
    // const filteredBluecoins = get(filteredBluecoinsAtom);
    const zoomData = get(zoomAtom);
    const boundsData = get(boundsAtom);
    const isClusterEnabledValue = get<boolean>(isClusterEnabledAtom);
    // if (filteredBluecoinsString.length > 0) {
    //   bluecoinsDataWorker = filteredBluecoins.filter((bluecoin: BluecoinData) =>
    //     selectedBluecoins.includes(bluecoin.bluecoinId)
    //   );
    // }

    SuperclusterBluecoinWorker.postMessage({
      bluecoinsData: bluecoinsDataWorker,
      bounds: boundsData,
      zoom: zoomData,
      minPoints: isClusterEnabledValue
        ? Config.onlineMap.superCluster.minPoints
        : MIN_POINTS_FOR_DISABLED_CLUSTER,
    });
    return bluecoinsDataWorker;
  },
});

export const isMinimalizedBleAtom = atom<boolean>({
  key: 'isMinimalizedBle',
  default: false,
});
