import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useIntl } from 'react-intl';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { keepPreviousData, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import './cmdTable.scss';
import { CmdLoader } from '@commander-services/gui-components';
import { JsonSchema } from 'json-schema-library';
import { ICmdTableFilter, ICmdTableSortFilter } from './types';
import useDragScroll from './hooks/useDragScroll';
import { getTableData } from './CmdTableService';
import { selectedVehiclesAtom } from '../../store/recoil/vehicles';
import { selectedCustomersAtom } from '../../store/recoil/customers';
import { filterForRequestAtomFamily, sortFilterAtomFamily } from './CmdTableState';

interface ICmdTableProps<T, D> {
  name: string;
  columns: ColumnDef<D>[];
  columnOrder: string[];
  columnVisibility: Record<string, boolean>;
  // fetchSize?: number;
  stickyColumns?: string[];
  lockedColumns?: string[];
  rowHeight?: number;
  onSelectedRowsChange?: (rows: D[], tableCount: number) => void;
  jsonSchema?: JsonSchema;
  leftStickyColumn?: string[];
  customNoDataMessage?: {
    title: string;
    text: string;
  };
  vehicleKey?: string;
  isSummaryRequired?: boolean;
  // isSelectedVehicleRequired?: boolean;
}

const ROW_HEIGHT_DEFAULT = 40;
const VEHICLE_KEY = 'vehicle';

function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function CmdTableWithSelectedVehicles<T, D>(props: ICmdTableProps<T, D>) {
  const { formatMessage: f } = useIntl();
  // we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const filterForRequest = useRecoilValue<ICmdTableFilter>(filterForRequestAtomFamily(props.name));
  const queryClient = useQueryClient();
  const sortFilter = useRecoilValue<ICmdTableSortFilter>(sortFilterAtomFamily(props.name));
  const selectedCustomers = useRecoilValue(selectedCustomersAtom);
  const selectedVehicles = useRecoilValue(selectedVehiclesAtom);
  // const tableFiltersKey = useRecoilValue(CmdTableState.tableFilters(props.name));

  const onMouseDown = useDragScroll(tableContainerRef);

  const { columns, columnOrder, columnVisibility, onSelectedRowsChange, jsonSchema } = props;
  const observerRef = React.useRef<IntersectionObserver | null>(null);
  const prevSelectedVehicles: number[] | undefined = usePrevious(selectedVehicles);
  const prevSelectedCustomers: number[] | undefined = usePrevious(selectedCustomers);

  const isDataRequestEnabled = (): boolean => {
    let enabled = true;
    // if (props.isSelectedVehicleRequired && selectedVehicles.length === 0) {
    //   enabled = false;
    // }
    if (selectedVehicles.length === 0) {
      enabled = false;
    }
    // if (
    //   prevSelectedCustomers &&
    //   prevSelectedCustomers.length !== selectedCustomers.length // &&
    // ) {
    //   enabled = true;
    // }
    // if (
    //   prevSelectedCustomers &&
    //   !selectedCustomers.every((customerId: number) => prevSelectedCustomers.includes(customerId))
    // ) {
    //   enabled = true;
    // }
    return enabled;
  };

  const updatedFilterForRequest = { ...filterForRequest };
  if (selectedVehicles.length > 0) {
    updatedFilterForRequest[props.vehicleKey || VEHICLE_KEY] = selectedVehicles;
  }
  if (
    prevSelectedCustomers &&
    prevSelectedCustomers.length !== selectedCustomers.length // &&
    // selectedCustomers.length === 0
  ) {
    queryClient.resetQueries({ queryKey: [props.name] });
  }
  if (selectedVehicles.length === 0) {
    queryClient.resetQueries({ queryKey: [props.name] });
  }
  if (
    // props.isSelectedVehicleRequired &&
    prevSelectedVehicles &&
    prevSelectedVehicles.length > 0 &&
    selectedVehicles.length === 0
  ) {
    updatedFilterForRequest[props.vehicleKey || VEHICLE_KEY] = [];
    // const queryKeys = [
    //   props.name,
    //   updatedFilterForRequest, // refetch when sorting changes
    //   sortFilter, // refetch when sorting changes
    //   selectedCustomers, // refetch when selected customers change
    //   selectedVehicles, // refetch when selected vehicles change
    // ];
    queryClient.resetQueries({ queryKey: [props.name] });
  }

  // react-query has a useInfiniteQuery hook that is perfect for this use case
  const queryKeys = [
    props.name,
    updatedFilterForRequest, // refetch when sorting changes
    sortFilter, // refetch when sorting changes
    selectedCustomers, // refetch when selected customers change
    selectedVehicles, // refetch when selected vehicles change
  ];

  const { data, fetchNextPage, isFetching, isLoading, hasNextPage } = useInfiniteQuery({
    queryKey: queryKeys,
    queryFn: async ({ pageParam = 0 }) => {
      const requestData = {
        offset: pageParam,
        filter: {
          ...updatedFilterForRequest,
        },
        sort: { ...sortFilter },
      };
      const fetchData = await getTableData<T>(props.name, requestData, jsonSchema);
      return fetchData;
    },
    initialPageParam: 0,
    // getNextPageParam: (_lastGroup, groups) => groups.length,
    getNextPageParam: (lastPage, allPages) => {
      const totalFetched = allPages.flatMap((page) => page?.records).length;

      return totalFetched < lastPage?.count ? totalFetched : undefined;
    },
    placeholderData: prevSelectedCustomers === selectedCustomers ? keepPreviousData : undefined,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isDataRequestEnabled(),
    gcTime: 0,
  });

  // flatten the array of arrays from the useInfiniteQuery hook
  const flatData = React.useMemo(() => data?.pages?.flatMap((page) => page?.records) ?? [], [data]);
  const totalDBRowCount: number = data?.pages?.[0]?.count ?? 0;
  // const totalFetched = flatData.length;

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  // const fetchMoreOnBottomReached = React.useCallback(
  //   (containerRefElement?: HTMLDivElement | null) => {
  //     if (containerRefElement) {
  //       const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
  //       // once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
  //       if (
  //         scrollHeight - scrollTop - clientHeight < 500 &&
  //         !isFetching &&
  //         totalFetched < totalDBRowCount
  //       ) {
  //         fetchNextPage();
  //       }
  //     }
  //   },
  //   [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  // );

  // // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  // React.useEffect(() => {
  //   fetchMoreOnBottomReached(tableContainerRef.current);
  // }, [fetchMoreOnBottomReached]);

  // Extract summaries data for footer
  const summaries = React.useMemo(() => {
    if (data?.pages?.[0]?.summaries) {
      const summaryMap: { [key: string]: string | string[] } = {};
      data.pages[0].summaries.forEach((summary) => {
        summary.cells.forEach((cell) => {
          summaryMap[cell.name] = cell.value;
        });
      });
      return summaryMap;
    }
    return {};
  }, [data]);

  // Utility function to join values for specific cases like fuel consumption
  const formatMultiValues = (values: string[]): string => {
    // Filter out values where the number part is 0.0
    const filteredValues = values.filter((value) => {
      const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
      return numericValue > 0;
    });
    // Join the remaining values with a newline
    return filteredValues.join('\n');
  };

  const renderFooterValue = (columnId: string, indexId?: number) => {
    if (!summaries) return null;

    if (indexId === 1) {
      return f({ id: 'table_list.summary.grandRowsTotal' });
    }

    const summaryValue = summaries[columnId];
    if (!summaryValue) return null;

    if (Array.isArray(summaryValue)) {
      // Special handling for values that come in multiple units
      return <div style={{ whiteSpace: 'pre-line' }}>{formatMultiValues(summaryValue)}</div>;
    }
    // For single value (string)
    return summaryValue;
  };

  const renderFooter = (header) => {
    // Check if the column.id exists in summaries
    const columnId = header.column.id;

    // If the column ID matches one of the summaries, use renderFooterValue
    if (summaries[columnId]) {
      return renderFooterValue(columnId);
    }

    // Otherwise, render the default footer if defined
    return flexRender(header.column.columnDef.footer, header.getContext());
  };

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      columnOrder,
      columnVisibility,
    },
    initialState: {
      columnOrder,
      columnPinning: {
        right: props.stickyColumns ?? [],
        left: props.leftStickyColumn ?? [],
      },
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    debugTable: true,
    onColumnOrderChange: (newOrder) => table.setColumnOrder(newOrder),
    onColumnPinningChange: (newPinning) => table.setColumnPinning(newPinning),
  });

  const { rows } = table.getRowModel();

  React.useEffect(() => {
    const selectedRowModels = table.getSelectedRowModel().rows.map((row) => row.original);
    if (onSelectedRowsChange) onSelectedRowsChange(selectedRowModels, totalDBRowCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel().rows]);

  const rowVirtualizer = useVirtualizer({
    count: flatData.length,
    estimateSize: React.useCallback(() => props.rowHeight || ROW_HEIGHT_DEFAULT, [props.rowHeight]), // estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    // measure dynamic row height, except in firefox because it measures table border height incorrectly
    // measureElement:
    //   typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
    //     ? (element) => element?.getBoundingClientRect().height
    //     : undefined,
    overscan: 15,
    onChange: (virtualizer) => {
      // measure the table height when the first row is rendered
      if (flatData.length > 0 && virtualizer.getTotalSize() === 0) {
        virtualizer.measure();
      }
    },
  });

  const setIntersectionObserver = React.useCallback(
    (node) => {
      if (isFetching) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching]
  );

  // scroll to top of table when sorting changes
  const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
    setSorting(updater);
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex?.(0);
    }
  };

  // since this table option is derived from table row model state, we're using the table.setOptions utility
  table.setOptions((prev) => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }));

  const getHeaderClassName = (
    columnId: string,
    isPinned: boolean | string,
    isStickyLeft: boolean
  ) => {
    if (isPinned && props.stickyColumns?.[0] === columnId) return 'first-sticky';
    if (isStickyLeft) return 'left-sticky';
    return '';
  };

  const getRowClassName = (columnId: string, isPinned: boolean | string, isStickyLeft: boolean) => {
    return `${isPinned ? 'sticky-col' : ''} ${
      isPinned && props.stickyColumns?.[0] === columnId ? 'first-sticky' : ''
    } ${isStickyLeft ? 'left-sticky' : ''}`;
  };

  if (isLoading) {
    return <CmdLoader inContent />;
  }

  const getTableHeight = () => {
    const windowHeight = window.innerHeight;
    if (windowHeight && windowHeight >= 600) {
      return `${windowHeight - 250}px`;
    }
    return '600px';
  };

  return (
    <div>
      <div className="table-results-total">
        {`${f({ id: 'table.results.total' })}:`}
        <strong>{` ${totalDBRowCount}`}</strong>
      </div>
      <div>
        {/* ({flatData.length} of {totalDBRowCount} rows fetched) */}

        <div
          className="cmd-table cmd-table-border"
          // onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
          onMouseDown={onMouseDown}
          ref={tableContainerRef}
          style={{
            overflow: 'auto', // our scrollable table container
            position: 'relative', // needed for sticky header
            height: getTableHeight(), // should be a fixed height
          }}
          id="cmd-table"
        >
          {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
          <table style={{ display: 'grid' }} className="m-0 border-0">
            <thead
              style={{
                display: 'grid',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                // width: '100%',
              }}
              id="cmd-table-header"
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: props.rowHeight || ROW_HEIGHT_DEFAULT,
                  }}
                >
                  {headerGroup.headers.map((header) => {
                    const isStickyLeft =
                      props.leftStickyColumn?.includes(header.column.id) ?? false;
                    return (
                      <th
                        key={header.id}
                        style={{
                          display: 'flex',
                          width: header.getSize(),
                          position: `${header.column.getIsPinned() ? 'sticky' : 'relative'}`,
                          right:
                            header.column.getIsPinned() === 'right'
                              ? `${header.column.getAfter('right')}px`
                              : undefined,
                          left: isStickyLeft ? `${header.column.getAfter('left')}px` : undefined,
                          zIndex: isStickyLeft || header.column.getIsPinned() ? 2 : 1,
                          // opacity: header.column.getIsPinned() ? 1 : 0.95,
                          // flex: `${
                          //   header.column.id === PARKING_TAG_MODIFIED_AT_KEY ? 1 : '0 1 auto'
                          // }`,
                          flex: `${
                            !props.stickyColumns?.includes(header.column.id) &&
                            !props.lockedColumns?.includes(header.column.id)
                              ? 1
                              : '0 1 auto'
                          }`,
                        }}
                        className={getHeaderClassName(
                          header.column.id,
                          header.column.getIsPinned(),
                          isStickyLeft
                        )}
                        // className="cmd-table-actions"
                      >
                        {/* <div
                        {...{
                          className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      > */}
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* </div> */}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody
              style={{
                display: 'grid',
                height: `${rowVirtualizer.getTotalSize()}px`, // tells scrollbar how big the table is
                position: 'relative', // needed for absolute positioning of rows
              }}
            >
              {rowVirtualizer.getVirtualItems().length === 0 && (
                <tr
                  className="p-6 d-flex align-items-center d-flex align-items-center text-center"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '360px',
                    fontSize: '24px',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <div data-cy={`cmd-table-${props.name}-no-data`} style={{ color: '#B3B3B3' }}>
                    {props.customNoDataMessage ? (
                      <>
                        {props.customNoDataMessage.title || f({ id: 'table.noData.title' })}
                        <br />
                        {props.customNoDataMessage.text ||
                          f({ id: 'table.noDataWithoutNavigator' })}
                      </>
                    ) : (
                      <>
                        {f({ id: 'table.noData.title' })}
                        <br />
                        {f({ id: 'table.noDataWithoutNavigator' })}
                      </>
                    )}
                  </div>
                </tr>
              )}
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<D>;
                return (
                  <tr
                    data-index={virtualRow.index} // needed for dynamic row height measurement
                    // ref={(node) => rowVirtualizer.measureElement(node)} // measure dynamic row height
                    ref={(node) => {
                      // Here you set the ref to link the virtual item to the DOM node
                      rowVirtualizer.measureElement(node);
                      if (virtualRow.index === flatData.length - 1) {
                        // Last row
                        setIntersectionObserver(node); // Now correctly setting the observer
                      }
                    }}
                    key={row.id}
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      transform: `translateY(${virtualRow.start}px)`, // this should always be a `style` as it changes on scroll
                      width: '100%',
                      height: `${virtualRow.size}px`, // dynamic row height
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const isStickyLeft =
                        props.leftStickyColumn?.includes(cell.column.id) ?? false;
                      return (
                        <td
                          key={cell.id}
                          style={{
                            display: 'flex',
                            width: cell.column.getSize(),
                            position: `${cell.column.getIsPinned() ? 'sticky' : 'relative'}`,
                            right:
                              cell.column.getIsPinned() === 'right'
                                ? `${cell.column.getAfter('right')}px`
                                : undefined,
                            left: isStickyLeft ? `${cell.column.getAfter('left')}px` : undefined,
                            // zIndex: cell.column.getIsPinned() ? 10 : 1,
                            // opacity: cell.column.getIsPinned() ? 1 : 0.95,
                            // flex: `${
                            //   cell.column.id === PARKING_TAG_MODIFIED_AT_KEY ? 1 : '0 1 auto'
                            // }`,
                            flex: `${
                              !props.stickyColumns?.includes(cell.column.id) &&
                              !props.lockedColumns?.includes(cell.column.id)
                                ? 1
                                : '0 1 auto'
                            }`,
                          }}
                          className={getRowClassName(
                            cell.column.id,
                            cell.column.getIsPinned(),
                            isStickyLeft
                          )}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {props.isSummaryRequired && rowVirtualizer.getVirtualItems().length > 0 && (
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id} style={{ display: 'flex', width: '100%' }}>
                    {footerGroup.headers.map((header, index) => {
                      const isStickyLeft =
                        props.leftStickyColumn?.includes(header.column.id) ?? false;
                      const isSticky = props.stickyColumns?.includes(header.column.id);
                      const stickyColumns = footerGroup.headers.filter((h) =>
                        props.stickyColumns?.includes(h.column.id)
                      );

                      // Merge sticky columns if there are at least 2
                      if (isSticky && stickyColumns.length >= 2) {
                        const firstStickyIndex = footerGroup.headers.findIndex((h) =>
                          props.stickyColumns?.includes(h.column.id)
                        );

                        // Render a merged sticky column only for the first sticky column
                        if (index === firstStickyIndex) {
                          const mergedWidth = stickyColumns.reduce(
                            (total, col) => total + col.getSize(),
                            0
                          );

                          return (
                            <th
                              key={header.id}
                              colSpan={stickyColumns.length} // Merge all sticky columns
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: mergedWidth - 1, // Combined width of sticky columns
                                position: 'sticky',
                                right: 0, // Sticky to the right
                                zIndex: 2,
                                justifyContent: 'center', // Adjust alignment
                                textAlign: 'center',
                                alignItems: 'center',
                                flex: `${
                                  !props.stickyColumns?.includes(header.column.id) &&
                                  !props.lockedColumns?.includes(header.column.id)
                                    ? 1
                                    : '0 1 auto'
                                }`,
                                // Remove right border for the first column
                              }}
                              className={getHeaderClassName(
                                header.column.id,
                                header.column.getIsPinned(),
                                isStickyLeft
                              )}
                            >
                              {renderFooter(header)}
                            </th>
                          );
                        }

                        // Skip rendering for other sticky columns as they're merged
                        return null;
                      }

                      // Render normal footer cells for other columns
                      return (
                        <th
                          key={header.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: header.getSize(),
                            // width:
                            //   footerGroup.headers.length - 3 === index
                            //     ? header.getSize()
                            //     : header.getSize(),
                            position: `${isStickyLeft ? 'sticky' : 'relative'}`,
                            left: isStickyLeft ? `${header.column.getAfter('left')}px` : undefined,
                            zIndex: isStickyLeft ? 2 : 1,
                            justifyContent: 'flex-end',
                            textAlign: 'right',
                            alignItems: 'center',
                            flex: `${
                              !props.stickyColumns?.includes(header.column.id) &&
                              !props.lockedColumns?.includes(header.column.id)
                                ? 1
                                : '0 1 auto'
                            }`,
                            borderRight: index === 0 ? 'none' : undefined,
                            marginRight: index === 0 ? '1px' : undefined,
                          }}
                          className={getHeaderClassName(
                            header.column.id,
                            header.column.getIsPinned(),
                            isStickyLeft
                          )}
                        >
                          {renderFooter(header)}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
        </div>
        {isFetching && <div className="table-fetching-text">{f({ id: 'waypoints.loading' })}</div>}
      </div>
    </div>
  );
}
