import * as React from 'react';

interface ITooltipComponentProps {
  id?: string;
  overlay: React.ReactNode;
  type?: string;
  firstItems?: boolean;
  positionLeft?: boolean; // default position is center
  top?: string;
  bottom?: string;
  display?: string;
  width?: string;
  positionRight?: boolean;
  style?: { [key: string]: string };
  children: React.ReactNode;
}

function TooltipComponent(props: ITooltipComponentProps) {
  const [tooltip, setTooltip] = React.useState<number | boolean>(false);

  const handleOnMouseLeave = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setTooltip(false);
  };

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setTooltip(event.clientY);
  };

  const getTooltip = () => {
    const head = document.getElementById('tableThead');
    let headTopValue = 0;
    if (head) {
      const headTop = head.getBoundingClientRect().top;
      if (headTop > -1) {
        headTopValue = headTop;
      }
    }

    let attr = props.width !== 'auto' ? 'bottom' : '';
    let value = props.width !== 'auto' ? '27px' : '';
    if (props.top) {
      value = props.top;
    }

    if (
      (tooltip &&
        ((Number(tooltip) - headTopValue < 330 && props.type === 'large') ||
          Number(tooltip) < 110)) ||
      props.firstItems
    ) {
      attr = 'top';
      value = '25px';
      if (props.bottom) {
        value = props.bottom;
      }
    }

    if (props.style && Object.keys(props.style).length > 0) {
      attr = Object.keys(props.style)[0];
      value = Object.values(props.style)[0];
    }

    return (
      <div
        style={{
          position: 'absolute',
          background: '#494949',
          width: props.width || '360px',
          [props.positionRight ? 'right' : 'left']: props.positionRight
            ? `-${props.width}` || '-360px'
            : props.positionLeft || props.width === 'auto'
              ? '0'
              : 'calc(-180px + 50%)',
          padding: '12px',
          zIndex: 1000,
          // whiteSpace: 'pre-wrap',
          fontSize: '12px',
          borderRadius: '4px',
          [attr]: value,
          textAlign: 'left',
        }}
      >
        {props.overlay}
      </div>
    );
  };

  return (
    <div
      data-cy={`tooltip-${props.id}`}
      style={{
        position: 'relative',
        overflow: 'initial',
        display: props.display || '',
        maxWidth: '100%',
      }}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {tooltip !== false && props.width !== 'auto' && getTooltip()}
      <div>{props.children}</div>
      {tooltip !== false && props.width === 'auto' && getTooltip()}
    </div>
  );
}

export default TooltipComponent;
