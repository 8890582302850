import * as React from 'react';
import { useIntl } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import useAnalytics from '../hooks/useAnalytics';
import ApiService from '../Services/ApiService';
import { languages } from '../Services/LanguageService';
import { IApplicationState } from '../StoreProvider';
import ConfirmDialog from './ConfirmDialog';
import showMessage from './Toastr/ToastService';
import LoaderService from '../Services/LoaderService';
import { activeLanguageAtom } from './MainMenu/MainMenuState';

interface IComponentProps {
  context: IApplicationState;
}

export default function Languages(props: IComponentProps): JSX.Element {
  const { trackEvent } = useAnalytics();
  const { formatMessage: f } = useIntl();
  const activeLanguage = localStorage.language;
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState<string | null>(null);
  const [languageTypeMessage, setLanguageTypeMessage] = React.useState('');
  const setLanguage = useSetRecoilState<string | null>(activeLanguageAtom);

  const languageTypes: { [index: string]: string } = {
    ensk: 'Slovak',
    encs: 'Czech',
    enhu: 'Hungary',
    sken: 'Anglický',
    skcs: 'Český',
    skhu: 'Maďarský',
    csen: 'Anglický',
    cssk: 'Slovenčina',
    cshu: 'Maďarský',
    husk: 'szlovák',
    hucz: 'cseh',
    huen: 'angol',
  };

  const handleChangeLanguage = async (language: string) => {
    if (language && language !== activeLanguage) {
      LoaderService.showLoader();
      const response = await ApiService.put(`/v1/user/${language}/locale`, {}, false);

      if (response && props.context.setLanguage) {
        localStorage.language = language;
        LoaderService.showLoader(false);
        props.context.setLanguage(language);
        setLanguage(language);

        window.location.replace(window.location.pathname + window.location.search);
      }
    }
  };

  const handleCloseConfirmDialog = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent
  ) => {
    event.preventDefault();
    setIsConfirmDialogOpen(false);
  };

  const handleOpenConfirmDialog = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const language = event.currentTarget.getAttribute('data-language');
    trackEvent(['menu', 'click', `Open language confirm dialog: ${language}`]);

    if (language && language !== activeLanguage) {
      setSelectedLanguage(event.currentTarget.getAttribute('data-language'));
      const types = activeLanguage + language;

      setLanguageTypeMessage(languageTypes[types]);
      setIsConfirmDialogOpen(true);
    } else {
      showMessage(
        f({ id: 'menu.changeLanguage' }),
        f({ id: 'menu.changeLanguage.toaster.message' }),
        'info',
        6000
      );
    }
  };

  const handleConfirmLanguage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    trackEvent(['menu', 'click', `Confirm language: ${selectedLanguage}`]);

    if (selectedLanguage) {
      handleChangeLanguage(selectedLanguage);
    }
    setIsConfirmDialogOpen(false);
  };

  return (
    <div>
      {isConfirmDialogOpen && (
        <ConfirmDialog
          title={f({ id: 'menu.changeLanguage' })}
          cancelCallback={handleCloseConfirmDialog}
          continueCallback={handleConfirmLanguage}
          cancelButtonTitle={f({ id: 'carsGroups.confirm.dialog.cancel' })}
          continueButtonTitle={f({ id: 'menu.changeLanguage.confirm.dialog.confirm.button' })}
        >
          {f({ id: 'menu.changeLanguage.confirm.dialog.message' }, { name: languageTypeMessage })}
        </ConfirmDialog>
      )}
      {Object.keys(languages).map((language: string) => (
        <a
          href="#"
          key={language}
          data-language={language}
          onClick={handleOpenConfirmDialog}
          style={{ fontWeight: language === activeLanguage ? 'bold' : 'normal' }}
        >
          {languages[language]}
        </a>
      ))}
    </div>
  );
}
