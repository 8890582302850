import { RecoilValue, RecoilState, useRecoilCallback } from 'recoil';

interface RecoilOutside {
  get?: <T>(atom: RecoilValue<T>) => T;
  getPromise?: <T>(atom: RecoilValue<T>) => Promise<T>;
  set?: <T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void;
  reset?: (atom: RecoilState<any>) => void;
}

const recoilOutside: RecoilOutside = {};

export const getRecoil = <T>(atom: RecoilValue<T>): T => {
  return recoilOutside.get!(atom);
};

export const getRecoilPromise = <T>(atom: RecoilValue<T>): Promise<T> => {
  return recoilOutside.getPromise!(atom);
};

export const setRecoil = <T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)): void => {
  recoilOutside.set!(atom, valOrUpdater);
};

export const resetRecoil = (atom: RecoilState<any>): void => {
  recoilOutside.reset!(atom);
};

/**
 * Access and set recoil state outside of React components
 * @returns {null}
 */
function RecoilOutside(): null {
  recoilOutside.get = useRecoilCallback<[atom: RecoilValue<any>], any>(
    ({ snapshot }) =>
      <T>(atom: RecoilValue<T>) => {
        return snapshot.getLoadable(atom).contents;
      },
    []
  );

  recoilOutside.getPromise = useRecoilCallback<[atom: RecoilValue<any>], Promise<any>>(
    ({ snapshot }) =>
      <T>(atom: RecoilValue<T>) => {
        return snapshot.getPromise(atom);
      },
    []
  );

  recoilOutside.set = useRecoilCallback<any, any>(({ set }) => set, []);
  recoilOutside.reset = useRecoilCallback(({ reset }) => reset, []);

  return null;
}

export default RecoilOutside;
