import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import { useLocation } from 'wouter';
import { URL_TRIPS_OVERVIEW } from '../router/constants';
import { selectedVehiclesAtom } from '../store/recoil/vehicles';

export interface ITabSync {
  type: string;
  name: string;
  value: any;
}

const SELECTED_VEHICLES_ATOM = 'selectedVehicles';
const SYNC_STATE = 'sync_state';

export const broadcastChannel = new BroadcastChannel('selectedVehicles');

/**
 * Access and set recoil state outside of React components
 * @returns {null}
 */
function Broadcast(): null {
  const [location, _setLocation] = useLocation();
  const setSelectedVehicles = useSetRecoilState<number[]>(selectedVehiclesAtom);

  broadcastChannel.onmessage = (event: MessageEvent<ITabSync>) => {
    if (
      event &&
      event.data.type === SYNC_STATE &&
      event.data.name === SELECTED_VEHICLES_ATOM &&
      location !== URL_TRIPS_OVERVIEW
    ) {
      setSelectedVehicles(event.data.value);
    }
  };

  return null;
}

export default Broadcast;
