import React from 'react';
import { useRecoilState } from 'recoil';
import RangeSelectFilter from '../Filter/RangeSelectFilter';
import useFilter from '../hooks/useFilter';
import SortButton from './SortButton';
import { tableFilterAtomFamily } from '../CmdTableState';

interface ThRangeProps {
  id: string;
  title: string;
  tooltip?: string;
  tableName: string;
  isSortable: boolean;
  unit?: string;
  minValue?: string;
  maxValue?: string;
}
const MIN_VALUE = '0';
const MAX_VALUE = '9999999';

function ThRange(props: ThRangeProps): JSX.Element {
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    tableFilterAtomFamily({ [props.tableName]: props.id })
  );
  const { getFilterData } = useFilter(props.tableName);
  const minValue = props.minValue || MIN_VALUE;
  const maxValue = props.maxValue || MAX_VALUE;

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: true,
          isActionChipsOpen: false,
        }
    );

    if (!tableActionsFilters) {
      getFilterData(props.tableName, props.id);
    }
  };

  const renderRangeSelectFilter = () => {
    if (
      tableActionsFilters &&
      tableActionsFilters.isOpen &&
      !tableActionsFilters.isActionChipsOpen &&
      tableActionsFilters.data
    ) {
      return (
        <div className="open">
          <RangeSelectFilter
            id={props.id}
            tableName={props.tableName}
            title={props.title}
            valueFrom={tableActionsFilters.values?.min}
            valueTo={tableActionsFilters.values?.max}
            defaultMinValue={
              tableActionsFilters.data.minValue ? tableActionsFilters.data.minValue : minValue
            }
            defaultMaxValue={
              tableActionsFilters.data.maxValue ? tableActionsFilters.data.maxValue : maxValue
            }
            suffix={props.unit}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`cmd-table-actions ${
        tableActionsFilters && tableActionsFilters.values ? 'active' : ''
      }`}
      style={{
        flex: 1,
        position: 'relative',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
      }}
      title={props.tooltip}
    >
      <div className="d-flex w-100">
        <div
          id={props.id}
          onClick={handleOnClick}
          className="text-truncate"
          data-cy={`filter-button-text-${props.id}`}
        >
          {props.title}
        </div>
        <a
          href="#"
          className="filter cmd-icons cmd-icon-filter"
          id={props.id}
          onClick={handleOnClick}
          data-cy={`filter-button-${props.id}`}
        >
          {/* filter icon */}
        </a>
        {props.isSortable && <SortButton id={props.id} tableName={props.tableName} />}
      </div>
      {renderRangeSelectFilter()}
    </div>
  );
}

export default React.memo(ThRange);
