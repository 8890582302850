import * as React from 'react';

interface ThTextProps {
  title: string;
  // maxWidth?: string;
  // minWidth?: string;
  tooltip?: string;
}

function ThText(props: ThTextProps): JSX.Element {
  return (
    <div
      // className="th-sticky"
      style={{
        whiteSpace: 'nowrap',
        // minWidth: props.minWidth || '150px',
        // maxWidth: props.maxWidth || '300px',
        flex: '1',
      }}
      title={props.tooltip}
    >
      <div className="text-truncate">{props.title}</div>
    </div>
  );
}

export default ThText;
