import * as React from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { CmdModal, CmdTooltip } from '@commander-services/gui-components';
import DownloadSelect from '../../../../DownloadSelect';
import SvgIconService from '../../../../../Services/SvgIconService';
import {
  COLUMN_ASSEGNED_USERS,
  TABLE_ECO_DRIVE,
  TABLE_GDPR_PROFILES,
  TABLE_GDPR_PROFILES_HISTORY,
  TABLE_PERIOD_LOCK,
  TABLE_PERIOD_LOCK_DEFINITION,
} from '../../../../../Services/TableService';
import VehiclesList from './VehiclesList';
import { localForageStore } from '../../../../../store/localForageStore';
import FuelCardsList from './FuelCardsList';
import { getRequest } from '../../../../../Services/HttpService';
import { AxiosResponse } from 'axios';

const restrictedIcon = SvgIconService.adminLock();

export const WORD_RULE = 5;
export const RAW_TEXT = 30;
const PRIVATE_RIDE_DEFAULT = 'privateRideDefault';

interface IValue {
  item: string;
  value: string | number;
}

interface IComponentProps {
  i: string;
  title: any;
  data: any;
  dateFormat: string;
  links: any;
  firstItems: boolean;
  lastItems: boolean;
  countRows?: number;
  tableName: string;
  systemData?: any;
  isFirstColumn: boolean;
  id?: number;
  customerId: number | null;
}

function TableTd(props: IComponentProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [openedItemsList, setOpenedItemsList] = React.useState<string[]>([]);
  const [vehiclesList, setVehiclesList] = React.useState<any>(null);
  const [fuelCardsList, setFuelCardList] = React.useState<any>(null);

  const getKeyByValue = (
    object: Record<string, any>,
    value: Record<string, any> | number
  ): string => {
    if (Object.keys(object).length > 0) {
      const objectValues: any = Object.values(object);
      const filterArr: any = objectValues
        .map(
          (item: any) =>
            Object.values(item).filter((subItem: any) => subItem && subItem.value === value)[0]
        )
        .filter(Boolean)[0];
      const filterArrItem = filterArr ? filterArr.item : '';
      const foundObjectValues = objectValues.find((item: any) => item.value === value) || {};
      const tdItem =
        foundObjectValues.tooltip || foundObjectValues.label
          ? foundObjectValues
          : foundObjectValues.item;
      const filteredItem =
        objectValues.find((item: any) => item.value === value) === undefined ? '' : tdItem;
      return objectValues[0].item === undefined ? filterArrItem : filteredItem;
    }
    return '';
  };

  const getKeyAndTooltip = (object: Record<string, any>, value: Record<string, any>): any => {
    if (Object.keys(object).length > 0) {
      const objectValues: any = Object.values(object);
      return Object.values(objectValues).find((i: any) => i.value === value);
    }
    return {};
  };

  const getKeysByValues = (object: Record<string, any>, values: number[]): any => {
    const uniqueValues = Array.from(new Set(values));
    if (Object.keys(object).length > 0) {
      const objectValues: any = Object.values(object);
      const newValues: string[] = [];
      uniqueValues.forEach((value: number) => {
        const filteredValues = objectValues.filter((i: IValue) => i.value === value);

        if (filteredValues) {
          const filteredValue = filteredValues[0];
          if (filteredValue && filteredValue.item) {
            newValues.push(filteredValue.item);
          }
        }
      });
      return newValues;
    }
    return [];
  };

  const showItemsInModal = (): void => {
    const values =
      props.systemData && props.data.name !== 'assignedRoles'
        ? props.systemData
        : props.data.filter && props.data.filter.values
          ? getKeysByValues(props.data.filter.values, props.systemData || props.title)
          : props.title;

    setOpenedItemsList(
      values && values.length > 0 ? values : [f({ id: `tripsOverview.modal.noData` })]
    );
  };

  const showFuelCardsInModal = (): void => {
    setFuelCardList(props.title);
  };
  const closeFuelCardsInModal = (): void => {
    setFuelCardList(null);
  };
  const showVehiclesInModal = (): void => {
    setVehiclesList(props.title);
  };

  const closeVehiclesOInModal = (): void => {
    setVehiclesList(null);
  };

  const handleClose = (): void => {
    setOpenedItemsList([]);
  };

  const getClassname = (value: string) => {
    let className = '';
    switch (value) {
      case 'create':
        className = 'cmd-icon cmd-icon-add';
        break;
      case 'remove':
        className = 'cmd-icon cmd-icon-bin';
        break;
      case 'edit':
        className = 'cmd-icon cmd-icon-edit';
        break;
      case 'current':
        className = 'cmd-icon cmd-icon-active';
        break;
      case 'unlocked':
        className = 'cmd-icon cmd-icon-unlock';
        break;
      case 'locked':
        className = 'cmd-icon cmd-icon-lock';
        break;
      default:
        className = 'cmd-icon cmd-icon-hourglass';
    }
    return className;
  };

  const showCallingDataInModal = async () => {
    const response: AxiosResponse | false = await getRequest(
      `/v1/gdpr/profile/${props.id}/users${
        props.systemData && props.systemData.filteredCustomerId
          ? `?customerId=${props.systemData.filteredCustomerId}`
          : ''
      }`,
      { returnAxiosResponse: true }
    );
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.assignedUsers &&
      response.data.assignedUsers.length > 0
    ) {
      setOpenedItemsList(
        response.data.assignedUsers.map((user: { item: string }) => {
          return user;
        })
      );
    } else {
      setOpenedItemsList([f({ id: `tripsOverview.modal.noData` })]);
    }
  };

  const findIdForDataByEndpoint = () => {
    if (
      props.data.name === COLUMN_ASSEGNED_USERS &&
      props.tableName === TABLE_GDPR_PROFILES &&
      Number(props.title) > 1
    ) {
      return true;
    }
    return false;
  };

  const findNumberAndUnit = () => {
    const regexFindNumberAndUnit =
      /([0-9]|[0-9] )(km|m|cm|dm|mm|L|h|min|s|ot[/]min|ms|%|kg|g|EUR|€|CZK|Kč)([[0-9]| |[/]|$)/gi;
    const titleForTesting = props.title && props.title.value ? props.title.value : props.title;
    const result =
      regexFindNumberAndUnit.test(titleForTesting) || titleForTesting === +titleForTesting;
    return result && !Number.isInteger(titleForTesting);
  };

  const findDecimalNumber = () => {
    if (String(props.title) === '0') {
      return true;
    }
    if (props.title === '-' || !props.title) {
      return false;
    }
    const regexFindDecimalNumber = /^-?\d*(\.|,)?\d*$/;
    const titleForTesting = props.title && props.title.value ? props.title.value : props.title;
    const result =
      regexFindDecimalNumber.test(titleForTesting) || titleForTesting === +titleForTesting;
    return result && !props.data.filter?.values;
  };

  const tdStyle = () => {
    if (props.i === 'vin' || props.i === 'vehicles') {
      return {};
    }
    const foundIdForDataByEndpoint = findIdForDataByEndpoint();
    if (
      findNumberAndUnit() &&
      props.i !== 'info' &&
      props.i !== 'name' &&
      !foundIdForDataByEndpoint
    ) {
      return { textAlign: 'right' };
    }
    if (props.i === 'newProfile') {
      return { color: '#008040', fontWeight: 'bold' };
    }
    if (props.i === 'oldProfile') {
      return { color: '#b50536', fontWeight: 'bold' };
    }
    if (findDecimalNumber() && !foundIdForDataByEndpoint) {
      return { textAlign: 'right' };
    }
    return {};
  };

  const renderLockTd = (locked: string) => {
    let element: JSX.Element | null = null;
    if (locked === 'locked') {
      element = (
        <div style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          <i style={{ color: 'rgb(181, 5, 54)' }} className="cmd-icon cmd-icon-lock" />
        </div>
      );
    } else if (locked === 'restricted') {
      element = (
        <div style={{ verticalAlign: 'middle', textAlign: 'center' }}>
          <img src={restrictedIcon} alt="admin-lock" />
        </div>
      );
    } else {
      element = <div />;
    }
    return element;
  };

  const renderDate = () => {
    return dayjs(props.title).format(
      props.i === 'createdAt' || props.i === 'modifiedAt' ? 'D.M.YYYY H:mm' : props.dateFormat
    );
  };

  const handleModalOnClick = (name: string) => {
    switch (name) {
      case 'vehicles':
        return showVehiclesInModal;
      case 'fuelCards':
        return showFuelCardsInModal;

      default:
        return showItemsInModal;
    }
  };

  const renderItemsArray = (name: string, findItemsArray: number[]) => {
    return (
      <div
        className="e-table-label"
        style={{ cursor: 'pointer' }}
        onClick={handleModalOnClick(name)}
        data-cy={`chips-table-${props.i}`}
      >
        {props.systemData && props.data.name !== 'assignedRoles' ? (
          <span>{f({ id: 'periodLock.accordingToPeriod' })}</span>
        ) : (
          <span>
            {name === 'vehicleId' ||
            name === 'vehicleGroupId' ||
            name === 'waypointId' ||
            name === 'waypointIds' ||
            name === 'recipients'
              ? f({ id: `table.${name}` })
              : props.data.title}
            {': '}
            {findItemsArray.length}
          </span>
        )}
      </div>
    );
  };

  const renderTdWithList = () => {
    return (
      <div
        className="e-table-label"
        style={{ cursor: 'pointer' }}
        onClick={showCallingDataInModal}
        data-cy={`chips-table-${props.i}`}
      >
        <span>
          {props.data.title}
          {': '}
          {props.title}
        </span>
      </div>
    );
  };

  const renderBoolean = () => {
    if (props.data.name === PRIVATE_RIDE_DEFAULT) {
      return (
        <div className="text-center">
          <i
            className={`text-gray-lighten fs-4 cmd-icons cmd-icon-ride-mode-${
              props.title ? 'private' : 'work'
            }`}
          />
        </div>
      );
    }
    return (
      <div className="text-center">
        {props.title && <i className="cmd-icons cmd-icon-active text-success" />}
      </div>
    );
  };

  const renderErrorIcon = () => {
    return (
      <div className="text-center">
        {((!props.title && props.data.name === 'status') ||
          (props.title && props.data.name === 'error')) && (
          <div>
            <i
              style={{ fontSize: '18px' }}
              className="cmd-icon cmd-icon-exclamation text-primary"
            />
          </div>
        )}
      </div>
    );
  };

  const renderAttachment = () => {
    const tempTitle = props.title.map((item) => {
      if (!item.path.includes('customerId')) {
        return {
          ...item,
          path: `${item.path}${props.customerId ? `?customerId=${props.customerId}` : ''}`,
        };
      }
      return item;
    });

    return (
      <DownloadSelect data={tempTitle} lastItems={props.lastItems} countRows={props.countRows} />
    );
  };

  const renderDateRange = (dateRange: { from: Date; to: Date } | null) => {
    return (
      <div>
        {dateRange && dateRange.from ? dayjs(dateRange.from).format('D.M.YYYY H:mm') : ''} -{' '}
        {dateRange && dateRange.to ? dayjs(dateRange.to).format('D.M.YYYY H:mm') : ''}
      </div>
    );
  };

  const renderChipElement = (found: { value: string; item: string }) => {
    return (
      <div
        className={`e-table-label e-table-label--${
          found.value === 'current' || found.value === 'unlocked' || found.value === 'create'
            ? 'success'
            : found.value === 'locked' || found.value === 'remove'
              ? 'error'
              : 'info'
        }`}
      >
        <i className={`${getClassname(found.value)}`} />

        <span>{found.item}</span>
      </div>
    );
  };

  const renderChip = (found: { tooltip: string; value: string; item: string }) => {
    return found.tooltip ? (
      <CmdTooltip
        id={`${found.item}-${found.value}`}
        isTop={!props.firstItems}
        width={300}
        content={<div style={{ color: '#fff', whiteSpace: 'pre-wrap' }}>{found.tooltip}</div>}
      >
        {renderChipElement(found)}
      </CmdTooltip>
    ) : (
      renderChipElement(found)
    );
  };

  const renderTdTooltip = (found: string, tdTooltip: string) => {
    return (
      <CmdTooltip
        id={`${found}-${tdTooltip}`}
        width={160}
        content={<div style={{ color: '#fff', whiteSpace: 'pre-wrap' }}>{tdTooltip}</div>}
        placement={props.isFirstColumn ? 'left' : undefined}
        isTop={!props.firstItems}
      >
        {found}
      </CmdTooltip>
    );
  };

  const handleOpen = (event: React.MouseEvent<HTMLAnchorElement>): boolean => {
    const href = event.currentTarget.href;
    // If trip-map link is provided - store rides to indexedDb.
    if (href.includes('trip-map')) {
      const splitted = href.split('/');
      localForageStore.setItem('rides', [splitted[4]]);
      localForageStore.setItem('multi-rides', null);
    }
    return true;
  };

  const renderLinks = (found: string, hasLinks: string, words: any, title: string) => {
    return words.length > WORD_RULE ? (
      <CmdTooltip
        id={`${found}-${title}`}
        isTop={!props.firstItems}
        width={300}
        content={
          <div style={{ color: '#fff', whiteSpace: 'pre-wrap' }}>{found || props.title}</div>
        }
      >
        <a href={hasLinks} target="_blank" rel="noopener noreferrer" onClick={handleOpen}>
          {words.slice(0, WORD_RULE).join(' ')} ...
        </a>
      </CmdTooltip>
    ) : (
      <a
        href={hasLinks}
        target="_blank"
        rel="noopener noreferrer"
        onClick={hasLinks.includes('trip-map') ? handleOpen : undefined}
      >
        {found || title}
      </a>
    );
  };

  const renderColor = (title: string) => {
    return (
      <div
        style={{
          backgroundColor: title,
          margin: 'auto',
          height: 20,
          width: 20,
          borderRadius: 5,
          border: '1px #ccc solid',
        }}
      />
    );
  };

  const getShortText = (words: string[], text: string) => {
    if (text.length > RAW_TEXT) {
      return text.substring(0, RAW_TEXT);
    }
    return words.slice(0, WORD_RULE).join(' ');
  };

  const getGdprTd = () => {
    return (
      (props.data.name === 'address' ||
        props.data.name === 'map' ||
        props.data.name === 'coordinates') &&
      props.title === '-'
    );
  };

  const getTooltipText = (text: string, systemDataText: string) => {
    if (!systemDataText) {
      return <div style={{ color: '#fff', textAlign: 'left', whiteSpace: 'pre-wrap' }}>{text}</div>;
    }
    return (
      <div style={{ color: '#fff', textAlign: 'left', whiteSpace: 'pre-wrap' }}>
        {text}
        <br />
        <br />
        {systemDataText}
      </div>
    );
  };

  const hasMoreThanThreeDecimalPlaces = (value: string | number) => {
    if (typeof value !== 'number') {
      return false;
    }
    const valueString = value.toString();
    const decimalPointIndex = valueString.indexOf('.');
    if (decimalPointIndex === -1) {
      return false;
    }
    const decimalPart = valueString.substring(decimalPointIndex + 1);
    return decimalPart.length > 3;
  };

  const renderDefault = (words: any, found: string | number) => {
    const value = hasMoreThanThreeDecimalPlaces(found) ? Number(found).toFixed(3) : found;
    if (getGdprTd()) {
      return f({ id: 'rides.privateRide' });
    }

    let systemDataText = '';
    if (props.systemData && !props.systemData.hasPositions) {
      systemDataText = f({ id: 'refuelings.newRide.tooltip' });
    }

    const wordLength = words.reduce((acc, str) => acc + str.length, 0);

    return wordLength > RAW_TEXT || String(value).length > RAW_TEXT ? (
      <CmdTooltip
        id={`${value}`}
        isTop={!props.firstItems}
        content={getTooltipText(value || words.join(''), systemDataText)}
        width={200}
        placement={props.firstItems ? 'left' : undefined}
      >
        <div>{getShortText(words, String(value))} ...</div>
      </CmdTooltip>
    ) : value !== undefined && value !== null ? (
      value
    ) : (
      words.join('')
    );
  };

  const getVehicleName = (vehicle: any) => {
    if (vehicle) {
      // return `${vehicle.licenceNumber} - ${vehicle.name}`;
      return vehicle.licenceNumber;
    }
    return '';
  };
  const getFuelCardName = (fuelCard: any) => {
    if (fuelCard) {
      return `${
        fuelCard.provider
          ? `${fuelCard.provider} - ${fuelCard.cardNumber}`
          : `${fuelCard.cardNumber}`
      } `;
    }
    return '';
  };

  const getHasPostions = () => {
    if (!props.systemData) {
      return true;
    }
    if (props.systemData && props.systemData.hasPositions) {
      return true;
    }
    return false;
  };

  const renderTd = () => {
    // words
    const { name } = props.data;
    const sentence = typeof props.title === 'string' ? props.title : '';
    const words = sentence.replace(/([ ,;]+)/g, '$1§sep§').split('§sep§');

    // default
    const regexFindColor = /#[0-9a-f]{6}|#[0-9a-f]{3}/gi;
    const regexFindDate = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z/gi;
    let isBoolean = false;
    let isErrorIcon = false;
    let locked = '';
    const findFilter = 'Select';
    let hasLinks = '';
    let dateRange = null;
    let findAttachment = false;
    let findItemsArray: number[] | null = null;
    let findIsIdForDataByEndpoint = false;
    let findVehicle: any = null;
    let findFuelCard: any = null;

    // locked
    if (props.data.name === 'locked') {
      locked = props.title;
    }

    // boolean
    if (props.title === true || props.title === false) {
      if (props.data && (props.data.name === 'error' || props.data.name === 'status')) {
        isErrorIcon = true;
      } else {
        isBoolean = true;
      }
    }

    // color
    const findColor = regexFindColor.test(props.title);

    // chip
    const isChip =
      (props.data.name === 'status' && props.tableName === TABLE_PERIOD_LOCK) ||
      (props.data.name === 'changeType' && props.tableName === TABLE_GDPR_PROFILES_HISTORY);

    // date
    const findDate = regexFindDate.test(props.title);

    // links
    if (props.links && Object.keys(props.links).length > 0 && props.links[props.i]) {
      const linkArr = props.links[props.i].split('/');
      if (!(linkArr.length > 1 && linkArr[1] === 'api') && getHasPostions()) {
        hasLinks = props.links[props.i];
        const urlValue = hasLinks;
        const urlArr = urlValue.split('/');
        if (urlArr.length === 3 && urlArr[1] === 'ride-map') {
          hasLinks = `/trip-map/${linkArr[2]}`;
        }
      }
    }

    // lock after custom
    if (
      props.systemData &&
      !props.systemData.filteredCustomerId &&
      props.systemData.hasPositions === undefined
    ) {
      findItemsArray = Array.from(new Set(props.systemData));
    }

    // dataByEndpoint
    if (
      props.data.name === COLUMN_ASSEGNED_USERS &&
      props.tableName === TABLE_GDPR_PROFILES &&
      Number(props.title) > 1
    ) {
      findIsIdForDataByEndpoint = true;
    }

    if (props.title && Object.values(props.title)[0]) {
      // date range
      if (props.title.from) {
        dateRange = props.title;
      } else if (Array.isArray(props.title)) {
        // attachment
        if (Object.values(props.title)[0].path) {
          const titleObjectValues: any = Object.values(props.title);
          findAttachment = !!titleObjectValues[0].path;
          // array length  at least 2
        } else if (props.title.length > 1) {
          findItemsArray = Array.from(new Set(props.title));
        } else if (props.title.length === 1 && props.title[0].licenceNumber) {
          findVehicle = props.title;
        } else if (
          props.title.length === 1 &&
          Object.prototype.hasOwnProperty.call(props.title[0], 'provider')
        ) {
          findFuelCard = props.title;
        }
      }
    }

    // find
    let find: boolean =
      findColor ||
      findDate ||
      isBoolean ||
      !!hasLinks ||
      findAttachment ||
      dateRange ||
      isErrorIcon ||
      findVehicle ||
      findFuelCard ||
      isChip ||
      !!locked ||
      !!findIsIdForDataByEndpoint ||
      !!findItemsArray;
    let found = find ? '' : props.title;

    // select filter data
    let selectFilterObject: any = null;
    if (
      props.data.filter !== null &&
      props.data.filter.type.search(findFilter) !== -1 &&
      props.data.filter.values
    ) {
      if (isChip) {
        selectFilterObject = getKeyAndTooltip(
          props.data.filter.values,
          props.title && Object.values(props.title).length === 1 ? props.title[0] : props.title
        );
      } else {
        found = getKeyByValue(
          props.data.filter.values,
          props.title && Object.values(props.title).length === 1 ? props.title[0] : props.title
        );
      }
    }

    // tooltip object
    let tdTooltip = '';
    if (!isChip) {
      if (found && (found.tooltip || found.item || found.value || found.label)) {
        tdTooltip =
          found.tooltip ||
          (props.data.name === 'customerId' && found.label ? found.item : '') ||
          '';
        find = !!tdTooltip;
        found =
          props.tableName === TABLE_ECO_DRIVE
            ? found.value
            : props.data.name === 'customerId' && found.label
              ? found.label
              : found.item;
      }
    }

    // systemData length 1
    if (findItemsArray && findItemsArray.length === 1 && props.systemData) {
      found = getKeyByValue(props.data.filter.values, findItemsArray[0]);
      findItemsArray = null;
      find = false;
    }

    // vehicles licenceNumber
    if (findVehicle) {
      found = getVehicleName(findVehicle[0]);
    }
    // fuelCards name
    if (findFuelCard) {
      found = getFuelCardName(findFuelCard[0]);
    }

    // render element
    let element: any = null;
    if (find) {
      if (locked) {
        element = renderLockTd(locked);
      } else if (findDate) {
        element = renderDate();
      } else if (findItemsArray) {
        element = renderItemsArray(name, findItemsArray);
      } else if (findIsIdForDataByEndpoint) {
        element = renderTdWithList();
      } else if (isBoolean) {
        element = renderBoolean();
      } else if (isErrorIcon) {
        element = renderErrorIcon();
      } else if (findAttachment) {
        element = renderAttachment();
      } else if (dateRange && !tdTooltip) {
        element = renderDateRange(dateRange);
      } else if (isChip) {
        element = renderChip(selectFilterObject);
      } else if (tdTooltip && !isChip) {
        element = renderTdTooltip(found, tdTooltip);
      } else if (hasLinks) {
        element = renderLinks(found, hasLinks, words, props.title);
      } else if (findColor) {
        element = renderColor(props.title);
      } else {
        element = renderDefault(words, found);
      }
    } else {
      element = renderDefault(words, found);
    }
    return element;
  };

  return (
    // <td key={props.i} onMouseOut={props.onMouseOut} onMouseOver={props.onMouseOver}>
    <td key={props.i} id={String(props.title)} style={tdStyle()}>
      {renderTd()}
      {vehiclesList && (
        <VehiclesList
          vehicles={vehiclesList}
          title={props.data.title}
          handleCloseModal={closeVehiclesOInModal}
        />
      )}
      {fuelCardsList && (
        <FuelCardsList
          fuelCards={fuelCardsList}
          title={props.data.title}
          handleCloseModal={closeFuelCardsInModal}
        />
      )}
      {openedItemsList && openedItemsList.length > 0 && (
        <CmdModal
          id="itemsListInModal"
          title={
            props.systemData && props.tableName === TABLE_PERIOD_LOCK_DEFINITION
              ? f({ id: `periodLock.lockAfterType` })
              : props.data.title
          }
          width="400px"
          footerButtons={[
            {
              id: 'tableTdModalBtn',
              type: 'button',
              title: f({ id: 'form.close' }),
              className: 'e-button',
              closeCallback: handleClose,
            },
          ]}
        >
          <div style={{ margin: '-24px -24px -12px -24px' }}>
            {openedItemsList.map((item: any, key: number) => {
              return (
                <div
                  style={{
                    padding: '10px 32px',
                    color: '#555',
                    background: key % 2 ? '#f0f0f0' : '',
                  }}
                >
                  {item && item.name ? (
                    <span>
                      {item.licenceNumber} - {item.name}{' '}
                      <span className="pull-right">{item.label}</span>
                    </span>
                  ) : item && item.item ? (
                    <span>
                      {item.item} <span className="pull-right">{item.label}</span>
                    </span>
                  ) : (
                    Object.values(item)
                  )}
                </div>
              );
            })}
          </div>
        </CmdModal>
      )}
    </td>
  );
}

export default TableTd;
