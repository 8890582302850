import * as React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ICmdTableColumn } from '../types';

interface ISortableItemProps {
  key: string;
  item: ICmdTableColumn;
  children: React.ReactNode;
  isLocked?: boolean;
}

export function SortableItem(props: ISortableItemProps): JSX.Element {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.item.key,
    disabled: props.isLocked,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    // opacity: props.isLocked ? 0.9 : 1,
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...(props.isLocked ? {} : attributes)}
      {...(props.isLocked ? {} : listeners)}
      data-cy={`cmdTable-settings-drag-${props.item.key}`}
    >
      {props.children}
    </li>
  );
}
